import React, { lazy, Suspense, useEffect, useState } from 'react';

import {Switch, Route, useLocation, Redirect} from 'react-router-dom';
import { PrivateRoute } from "PrivateRoute";
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';
import Data from "services/Data";

// Layout Blueprints

import {
  LeftSidebar,
  MinimalLayout
} from './layout-blueprints';

// Example Pages

import PageError404 from './example-pages/PageError404';

const Login = lazy(() => import('./pages/LoginPage'));
const Logout = lazy(() => import('./pages/LogoutPage'));
const PasswordRecover = lazy(() => import('./pages/PasswordRecoverPage'));
const ChangePassword = lazy(() => import('./pages/ChangePasswordPage'));
const Dashboard = lazy(() => import('./pages/DashboardPage'));
const Participants = lazy(() => import('./pages/PatientsPage'));
const Search = lazy(() => import('./pages/SearchPage'));
const ParticipantProfile = lazy(() => import('./pages/PatientProfilePage'));
const Tasks = lazy(() => import('./pages/TasksPage'));
const Schedule = lazy(() => import('./pages/SchedulePage'));
const Settings = lazy(() => import('./pages/SettingsPage'));
const UserProfile = lazy(() => import('./pages/UserProfilePage'));
const ReportsStats = lazy(() => import('./pages/ReportsPage'));
const FormStats = lazy(() => import('./pages/FormStatsPage'));
const CumulativeReport = lazy(() => import('./pages/CumulativePage'));

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#39567e'} loading={true} />
          </div>
        </div>
      </>
    );
  };

  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          <PrivateRoute path='/' exact>
            <Redirect to="/dashboard/" />
          </PrivateRoute>
          <PrivateRoute
            path={[
              '/dashboard/',
              '/referrals/',
              '/referrals/:patient_id/',
              '/profile/',
              '/search/',
              '/tasks/',
              '/schedule/',
              '/settings/',
              '/reports/form_stats/',
              '/reports/reports_stats/',
              '/reports/cumulative/',
            ]} exact>
            <LeftSidebar>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <PrivateRoute path="/dashboard/" component={Dashboard} exact />
                  <PrivateRoute path="/referrals/" component={Participants} exact />
                  <PrivateRoute path="/referrals/:patient_id/" component={ParticipantProfile} exact />
                  <PrivateRoute path="/profile/" component={UserProfile} exact />
                  <PrivateRoute path="/search/" component={Search} exact />
                  <PrivateRoute path="/tasks/" component={Tasks} exact />
                  <PrivateRoute path="/schedule/" component={Schedule} exact />
                  <PrivateRoute path="/settings/" component={Settings} exact />
                  <PrivateRoute path="/reports/form_stats/" component={FormStats} exact />
                  <PrivateRoute path="/reports/reports_stats/" component={ReportsStats} exact />
                  <PrivateRoute path="/reports/cumulative/" component={CumulativeReport} exact />
                </motion.div>
              </Switch>
            </LeftSidebar>
          </PrivateRoute>
          <Route
            path={[
              '/login/',
              '/reset-password/',
              '/reset-password/:reset_token/'
            ]} exact>
            <MinimalLayout>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/login/" component={Login} exact />
                  <Route path="/reset-password/" component={PasswordRecover} exact />
                  <Route path="/reset-password/:reset_token/" component={ChangePassword} exact />
                </motion.div>
              </Switch>
            </MinimalLayout>
          </Route>
          <Route path='/logout/' component={Logout} />
          <Route>
            <MinimalLayout>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route component={PageError404} />
                </motion.div>
            </MinimalLayout>
          </Route>
          
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
