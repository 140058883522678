import React, {useState, createContext} from 'react'

export const StoreContext = createContext(null);

export default ({ children }) => {

  const [trials, setTrials] = useState([]);
  const [currentTrial, setCurrentTrialInternal] = useState({});
  const [trialStats, setTrialStats] = useState({});
  const [trialStatuses, setTrialStatuses] = useState([]);
  const [trialSites, setTrialSites] = useState([]);
  const [trialPatients, setTrialPatients] = useState([]);          //{}
  const [trialPages, setTrialPages] = useState({});
  const [currentSite, setCurrentSiteInternal] = useState({});
  const [statusLoading, setstatusLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [patientStatus, setPatientStatus] = useState(null);

  const setCurrentTrial = selectedTrial => {
    if (selectedTrial.id) localStorage.setItem("trial_id", selectedTrial.id);
    setCurrentTrialInternal(selectedTrial);
  }

  const setCurrentSite = selectedSite => {
    if (!Object.keys(selectedSite).length) localStorage.setItem("site_id", 0);
    if (selectedSite.id) localStorage.setItem("site_id", selectedSite.id);
    setCurrentSiteInternal(selectedSite);
  }
  
  const store = {
    trials, setTrials,
    currentTrial, setCurrentTrial,
    trialStats, setTrialStats,
    trialStatuses, setTrialStatuses,
    trialSites, setTrialSites,
    trialPatients, setTrialPatients,
    trialPages, setTrialPages,
    currentSite, setCurrentSite,
    statusLoading, setstatusLoading,
    startDate, endDate,
    setStartDate, setEndDate,
    patientStatus, setPatientStatus
  }

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}
