import React, {useContext} from 'react';
import AuthService from "../../services/Auth";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  ListGroup,
  ListGroupItem,
  UncontrolledTooltip,
  Nav,
  NavItem,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';
import {StoreContext} from "../../utils/Store";

function get_level(trial) {
  if (typeof(trial.level) !== "number") return "Loading...";
  else if (trial.level === 2) return "Study Manager";
  else if (trial.level === 1) return "Study Monitor";
  else return "Study Operator";
}

const HeaderUserbox = () => {
  const user = AuthService.getCurrentUser().user;
  const { trials, setTrials, currentTrial, setCurrentTrial } = useContext(StoreContext);

  return (
    <>
      <UncontrolledDropdown className="position-relative ml-2">
        <DropdownToggle
          color="link"
          className="p-0 text-left d-flex btn-transition-none align-items-center" href="/profile/">
          <div className="d-block p-0 avatar-icon-wrapper">
            <Badge color="success" className="badge-circle p-top-a">
              Online
            </Badge>
            <div className="avatar-icon rounded bg-night-sky text-center">
              <FontAwesomeIcon
                icon={['fas', 'user']}
                className="text-white"
              />
            </div>
          </div>
          <div className="d-none d-xl-block pl-1">
            <div className="font-weight-bold mb-0 pb-0">{user.first_name} {user.last_name}</div>
            <span className="text-black-50 d-block" style={{lineHeight: "0.9"}}>{get_level(currentTrial)}</span>
          </div>
          
        </DropdownToggle>
      </UncontrolledDropdown>
    </>
  );
};

export default HeaderUserbox;
