class Token {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refresh;
  }
  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.access;
  }
  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.access = token;
    localStorage.setItem("user", JSON.stringify(user));
  }
  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
  renameUser(first_name, last_name) {
    let user = JSON.parse(localStorage.getItem("user"));
    user['user']['first_name'] = first_name;
    user['user']['last_name'] = last_name;
    localStorage.setItem("user", JSON.stringify(user));
  }
  updateAnnouncement(announcement_type, announcement_time) {
    let user = JSON.parse(localStorage.getItem("user"));
    user['user']['announcement_type'] = announcement_type;
    user['user']['announcement_time'] = announcement_time;
    localStorage.setItem("user", JSON.stringify(user));
  }
  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }
  removeUser() {
    localStorage.removeItem("user");
  }
}
export default new Token();
