import React, {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { PieChart, Users, User, Search } from 'react-feather';
import PatientStatusSwitch from "../../components/dashboard/PatientStatusSwitch";
import {Button, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DatePicker, { registerLocale } from "react-datepicker";
import {StoreContext} from "../../utils/Store";
import enGb from 'date-fns/locale/en-GB';
import "react-datepicker/dist/react-datepicker.css";

registerLocale('en-gb', enGb);

const PageTitle = (props) => {
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleIconBox,
    pageTitleDescription,
    titleHeading,
    titleDescription,
    titleIcon,
    titlePatient
  } = props;

  const getTitleIcon = () => {
    if (titleIcon === 'patients') return <Users className="display-2 text-primary" />;
    if (titleIcon === 'patient') return <User className="display-2 text-primary" />;
    if (titleIcon === 'search') return <Search className="display-2 text-primary" />;

    return <PieChart className="display-2 text-primary" />;
  }
  const { startDate, endDate, setStartDate, setEndDate } = useContext(StoreContext);
  const [closeStatus, setCloseStatus] = useState(false);
  const getRangeValue = () => {
    let str = "";
    if (startDate) {
      str += startDate.toLocaleDateString('en-GB');
    }
    if (endDate) {
      str += " - ";
      str += endDate.toLocaleDateString('en-GB');
    }
    return str;
  }

  return (
    <>
      <div
        className={clsx('app-page-title', 'pt-4', 'pb-4', pageTitleStyle, pageTitleBackground, {
          'app-page-title--shadow': pageTitleShadow
        })}>
        <div>
          <div className="app-page-title--first">
            {pageTitleIconBox && (
              <div className="app-page-title--iconbox d-70">
                <div className="d-70 d-flex align-items-center justify-content-center">
                  {getTitleIcon()}
                </div>
              </div>
            )}
            <div className="app-page-title--heading">
              <h1>{titleHeading}</h1>
              {pageTitleDescription && (
                <div className="app-page-title--description mt-0">
                  {titleDescription}
                </div>
              )}
            </div>
          </div>
        </div>
        {titlePatient && titlePatient.id && (
          <div><PatientStatusSwitch patient={titlePatient}/></div>)}
        {window.location.pathname === "/dashboard/" &&
          <div className={"d-flex m-0 form-group-datepicker-range"}>
            <FormGroup className={"mr-3 mb-0"}>
              {startDate && <Button color="dark" className="btn-clear-calendar btn-icon d-20 m-2" onClick={() => {
                setStartDate(null);
                setEndDate(null);
              }}>
                  <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['far', 'calendar-times']} className="font-size-sm"/>
                  </span>
              </Button>}
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={['far', 'calendar-alt']}/>
                  </InputGroupText>
                </InputGroupAddon>
                <DatePicker
                  id={"id-datepicker"}
                  selectsRange={true}
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="bottom-end"
                  onChange={(date) => {
                    if (!startDate && !endDate) {
                      setStartDate(date);
                    } else if (startDate && !endDate) {
                      if (date < startDate) {
                        setStartDate(date);
                        setEndDate(null);
                      } else {
                        setEndDate(date);
                      }
                    }
                    if (startDate && endDate) {
                      setStartDate(date);
                      setEndDate(null);
                      setCloseStatus(true);
                    }
                    if (endDate) setCloseStatus(false);
                    else setCloseStatus(true);
                  }}
                  onCalendarClose = {() => { setCloseStatus(true); }}
                  onCalendarOpen = {() => { setEndDate(null); setStartDate(null); setCloseStatus(false); }}
                  //selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  locale="en-gb"
                  placeholderText={"Select Date Range"}
                  className="dashboard-form-control"
                  value={getRangeValue()}
                  selectsEnd = {endDate}
                  selectsStart = {startDate}
                  shouldCloseOnSelect={closeStatus}
                  popperClassName="popper-datepicker-dashboard"
                  calendarClassName="calendar-datepicker-dashboard"
                />
              </InputGroup>
            </FormGroup>
          </div>}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

export default connect(mapStateToProps)(PageTitle);
