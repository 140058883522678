import React, {useState } from 'react';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { connect } from 'react-redux';

import { setHeaderSearchHover } from '../../reducers/ThemeOptions';




const HeaderSearch = (props) => {
  const history = useHistory();
  const [query, setQuery] = useState('');
  const { headerSearchHover, setHeaderSearchHover } = props;

  const toggleHeaderSearchHover = () => {
    setHeaderSearchHover(!headerSearchHover);
  };

  const handleSearch = (event) => {
    if(event.key === 'Enter'){
      window.location = "search/?q=" + query
    }
    
  };
  
  return (
    <>
      <div className="header-search-wrapper">
        <div
          className={clsx('search-wrapper', {
            'is-active': headerSearchHover
          })}>
          <label
            className="icon-wrapper text-black"
            htmlFor="header-search-input">
            <FontAwesomeIcon icon={['fas', 'search']} />
          </label>
          <input
            onFocus={toggleHeaderSearchHover}
            onBlur={toggleHeaderSearchHover}
            className="form-control"
            id="header-search-input"
            name="header-search-input"
            placeholder="Search Referrals..."
            type="search"
            onKeyPress={handleSearch}
            onInput={e => setQuery(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  headerSearchHover: state.ThemeOptions.headerSearchHover
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderSearchHover: (enable) => dispatch(setHeaderSearchHover(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSearch);
