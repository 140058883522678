import axios from "axios";
import TokenService from "./Token";
import Auth from "./Auth";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  }
});
instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url === "/auth/refresh/" && err.response) {
      Auth.logout();
      window.location = '/login/';
      return Promise.reject(err.response);
    }
    if (originalConfig.url !== "/auth/login/" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          let refreshToken = TokenService.getLocalRefreshToken();
          if (refreshToken) {
            const rs = await instance.post("/auth/refresh/", {
              refresh: refreshToken,
            });
            const {access} = rs.data;
            TokenService.updateLocalAccessToken(access);
          }
          return instance(originalConfig);
        } catch (_error) {
            Auth.logout();
            window.location = '/login/';
            return Promise.reject(err.response);
        }
      }
    }
    return Promise.reject(err.response);
  }
);
export default instance;
