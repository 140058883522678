import api from "./Api";
import TokenService from "./Token";

class Auth {
  login(email, password) {

    return api
      .post("/auth/login/", {
        email,
        password
      })
      .then(response => {
        if (response.data.access) {
          TokenService.setUser(response.data);
        }
        return response.data;
      });
  }
  logout() {
    TokenService.removeUser();
  }
  getCurrentUser() {
    return TokenService.getUser();
  }
  requestPasswordLink(email) {
    return api
    .post("/auth/password_reset/", {
      email
    })
    .then(response =>{
      return response.data
    });
  }
  changePasswordValue(token, password) {
    return api
    .post("/auth/password_reset/confirm/", {
      token,
      password
    })
    .then(response => {
      if (response.data.access) {
        TokenService.setUser(response.data);
      }
      return response
    });
  }
  verifyPasswordToken(token) {
    return api
    .post("/auth/password_reset/validate_token/", {
      token
    })
    .then(response => {
      return response
    });
  }
  changeUserPassword(user_id,old_password,password,password2) {
    return api
    .put("/auth/change_password/" + user_id + "/", {
      old_password,
      password,
      password2
    })
    .then(response => {
      return response
    });
  }
  updateUserData(user_id, first_name, last_name) {
    return api
    .put("/auth/update_profile/" + user_id +"/", {
      first_name,
      last_name
    })
    .then(response => {
      return response
    });
  }
  updateUserAnnouncement(user_id, announcement_type, announcement_time) {
    return api
      .put("/auth/update_announcement/" + user_id +"/", {
        announcement_type,
        announcement_time
      })
      .then(response => {
        return response
      });
  }
}
export default new Auth();
