import React, { useState, useEffect, useContext } from 'react';
import Data from '../../services/Data';
import { StoreContext } from '../../utils/Store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  UncontrolledPopover,
  Popover,
  Badge,
  Nav,
  NavItem,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem,
} from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';
import { splitBySeparators } from 'rc-select/lib/util';
import { Edit, ChevronRight } from 'react-feather';


const HeaderMenu = () => {
  const { trials, setTrials, currentTrial, setCurrentTrial, currentSite, setCurrentSite } = useContext(StoreContext);

  const [popoverOpen, setPopoverOpen] = useState(false);

  const popoverToggle = () => setPopoverOpen(!popoverOpen);

  const loadTrials = () => {
    Data.getTrials().then((result) => {
      setTrials(result.data);

    });
  }

  useEffect(() => {
    loadTrials();

  }, []);

  useEffect(() => {
    if (trials.length) {
      let trial_id = localStorage.getItem("trial_id");
      if (!trial_id) trial_id = 0;
      let selectedTrial = "";
      if (trial_id) {
        trials.map((trial) => {
          if (trial.id === parseInt(trial_id)) selectedTrial = trial;
        });
      }
      if (!selectedTrial) selectedTrial = trials[0];
      setCurrentTrial(selectedTrial);
      if (selectedTrial.sites.length) {
        if (selectedTrial.sites.length == 1) {
          setCurrentSite(selectedTrial.sites[0]);
        } else {
          let site_id = localStorage.getItem("site_id");
          if (!site_id) site_id = 0;
          let selectedSite = {};
          if (site_id) {
            selectedTrial.sites.map((site) => {
              if (site.id === parseInt(site_id)) {
                selectedSite = site
              }
            });
            setCurrentSite(selectedSite);
          } else {
            setCurrentSite({});
          }
        }
      } else {
        setCurrentSite({});
      }

    } else {
      setCurrentTrial({});
    }
  }, [trials])

  const showCurrentTrial = (item) => {
    if (item === currentTrial) return <strong className="text-primary">{item.name}</strong>;
    else return <>{item.name}</>;
  }

  const showCurrentSite = (site) => {
    if (site === currentSite) return <strong className='text-primary'>{site.name}</strong>;
    else return <>{site.name}</>;
  }

  const navigateDashboard = () => {
    if (window.location.pathname != "/dashboard/") window.location = "/dashboard/";
  }

  return (
    <>
      <div className="app-header-menu">
        <span className="position-relative">
          <Dropdown
            className="border-0 w-100 py-0"
            size="sm"
            isOpen={popoverOpen}
            toggle={popoverToggle}
          >
            <DropdownToggle outline color="primary" className="btn-sm text-left border-0 trial-button" caret>
              <strong className="d-block"> {currentTrial.name}</strong>
              <span>{currentTrial.code} (site: {currentSite.name ? currentSite.name : 'All'})</span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-mega-menu-lg">
              <Row className="no-gutters">
                <Nav pills className="nav-neutral-first flex-column pl-2 pr-2 w-100">
                  {trials.map(function (item) {
                    return <NavItem key={item.id} className="nav-item text-capitalize nomarg">
                      <NavLinkStrap
                        href="#"
                        onClick={
                          () => {
                            setCurrentSite(item.sites.length === 1 ? item.sites[0] : {});
                            setCurrentTrial(item);
                            popoverToggle();
                            navigateDashboard();
                          }
                        }
                        className="pl-2 dropdown-mega-menu-option">
                        <ChevronRight size="15" className='mr-1'/>
                        {showCurrentTrial(item)}
                      </NavLinkStrap>
                      <Nav pills className="nav-neutral-success flex-column m-0">
                        {item.sites.length > 1 && item.sites.map((site, index) => (
                          <div key={index} >
                            <NavItem className="nav-item text-capitalize text-secondary font-size-sm">
                              <NavLinkStrap
                                href="#"
                                onClick={() => { setCurrentSite(site); setCurrentTrial(item); popoverToggle(); navigateDashboard() }}
                                className="pl-2 dropdown-mega-menu-child">
                                <Edit size="15" className='mr-1 ml-4'/>
                                {showCurrentSite(site)}
                              </NavLinkStrap>
                            </NavItem>
                          </div>
                        ))}

                      </Nav>
                    </NavItem>
                  })}
                </Nav>
              </Row>
            </DropdownMenu>
          </Dropdown>
        </span>
      </div>
    </>
  );
};

export default HeaderMenu;
