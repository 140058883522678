import { SET_PAGE_TITLE_DESCRIPTION } from 'reducers/ThemeOptions';
import api from './Api';
import TokenService from "./Token";
class Data {
  
  getTrials() {
    return api.get('/trials/');
  }

  getUser() {
    return api.get('/users/');
  }

  getTrial(trial_id) {
    return api.get("/trials/"+trial_id+"/");
  }

  getTrialStats(trial_id, site_id, s_date, e_date) {
    let start_date;
    let end_date;
    if (s_date) {
      start_date = String(s_date.getDate()) + '.' + String(s_date.getMonth() + 1) + '.' + String(s_date.getFullYear());
    } else {
      start_date = "";
    }
    if (e_date) {
      end_date = String(e_date.getDate()) + '.' + String(e_date.getMonth() + 1) + '.' + String(e_date.getFullYear());
    } else {
      end_date = "";
    }
    return api.get("/trials/"+trial_id+"/site/"+site_id+"/stats/" +
      "?sdate=" + start_date +
      "&edate=" + end_date);
  }

  getTrialStatsUnit(trial_id, site_id, unit, s_date, e_date ) {
    let start_date;
    let end_date;
    if (s_date) {
      start_date = String(s_date.getDate()) + '.' + String(s_date.getMonth() + 1) + '.' + String(s_date.getFullYear());
    } else {
      start_date = "";
    }
    if (e_date) {
      end_date = String(e_date.getDate()) + '.' + String(e_date.getMonth() + 1) + '.' + String(e_date.getFullYear());
    } else {
      end_date = "";
    }
    return api.get("/trials/"+trial_id+"/sites/"+site_id+"/dashboard/"+ unit +
      "/?sdate=" + start_date +
      "&edate=" + end_date);
  }

  getTrialStatuses(trial_id) {
    return api.get("/trials/"+trial_id+"/statuses/");
  }

  getTrialSites(trial_id) {
    return api.get("/trials/"+trial_id+"/sites/");
  }

  getTrialPatients(trial_id, site_id, page=1, limit=10, order_by="created", sort_order="desc", s_date="", e_date = "", search_query="") {
    let start_date;
    let end_date;
    if (s_date) {
      start_date = String(s_date.getDate()) + '.' + String(s_date.getMonth() + 1) + '.' + String(s_date.getFullYear());
    } else {
      start_date = "";
    }
    if (e_date) {
      end_date = String(e_date.getDate()) + '.' + String(e_date.getMonth() + 1) + '.' + String(e_date.getFullYear());
    } else {
      end_date = "";
    }
    return api.get("/trials/" + trial_id + "/site/" + site_id +
      "/patients/?page=" + page +
      "&limit=" + limit+
      "&order_by=" + order_by +
      "&order_order=" + sort_order +
      "&sdate=" + start_date +
      "&edate=" + end_date +
      "&q=" + search_query);
  }

  getTrialPatientsReports(trial_id, site_id, page=1, limit=10, order_by="created", sort_order="desc", search_query="") {
    return api.get("/trials/" + trial_id + "/site/" + site_id +
      "/patients_action_report/?page=" + page +
      "&limit=" + limit+
      "&order_by=" + order_by +
      "&order_order=" + sort_order +
      "&q=" + search_query);
  }

  getTrialPatient(trial_id, patient_id) {
    return api.get("/trials/"+trial_id+"/patients/"+patient_id+"/")
  }

  getTrialTemplates(trial_id) {
    return api.get("/trials/"+trial_id+"/trial_templates/")
  }

  getPatientEmailTemplate(trial_id, patient_id, template_id) {
    return api.get("/trials/"+trial_id+"/patient/"+patient_id+"/template/"+template_id+"/get_patient_email_template/")
  }

  sendEmailTemplate(trial_id, patient_id, subject, to, cc, bcc, body, template_id) {
    const data = {
      "patient": patient_id,
      "template": template_id,
      "subject": subject,
      "to": to,
      "cc": cc,
      "bcc": bcc,
      "body": body
    }
    return api.post("/trials/"+trial_id+"/send_patient_email_template/", data)
  }

getPatientSmsTemplate(trial_id, patient_id, template_id) {
  return api.get("/trials/"+trial_id+"/patient/"+patient_id+"/template/"+template_id+"/get_patient_sms_template/")
}

sendSmsTemplate(trial_id, patient_id, to, body, template_id) {
  const data = {
    "patient": patient_id,
    "template": template_id,
    "to": to,
    "body": body
  }
  return api.post("/trials/"+trial_id+"/send_patient_sms_template/", data)
}
//sms end
  setPatientStatus(trial_id, patient_id, status_id, manager_note) {
    return api.post("/trials/"+trial_id+"/set_patient_status/", {"patient": patient_id, "status":status_id, "note":manager_note})
  }

  getPatientForms(trial_id, patient_id) {
    return api.post("/trials/"+trial_id+"/get_patient_forms/", {"patient": patient_id})
  }

  getNotes(trial_id, patient_id) {
    return api.get("/trials/"+trial_id+"/patients/"+patient_id+"/notes/")
  }

  createNote(trial_id, patient_id, text) {
    return api.post("/trials/"+trial_id+"/patients/"+patient_id+"/note_create/", {'trial':trial_id,"patient": patient_id,"body":text})
  }

  updateManagerNote(trial_id, patient_id, text) {
    return api.post("/trials/"+trial_id+"/patients/"+patient_id+"/manager_note_update/", {'trial':trial_id,"patient": patient_id,"body":text})
  }

  editNote(trial_id, patient_id, text, note_id) {
    return api.post("/trials/"+trial_id+"/patients/"+patient_id+"/notes/"+note_id+"/edit/", {'trial':trial_id,"patient": patient_id,"body":text})
  }

  setFile(trial_id, patient_id, file) {
    const config = {
      headers:  {'Content-Type': 'multipart/form-data'},
    }
    const formData = new FormData();
    formData.append('file', file,);
    return api.post("/trials/"+trial_id+"/patients/"+patient_id+"/file_upload/", formData, config);
  }

  getFile(trial_id, patient_id, note_id) {
    return api.get("/trials/"+trial_id+"/patients/"+patient_id+"/notes/"+note_id+"/download/", { responseType : "blob" })
  }

  getStatsData(trial_id, site_id) {
    return api.get("/trials/" + trial_id + "/site/" + site_id + "/report_action_stats/");
  }

  getTrialFormStatsData(trial_id) {
    return api.get("/trials/"+trial_id+"/form_data_stats/");
  }

  getTrialCumulativeReport(trial_id, startDate, endDate) {
    let start_date;
    let end_date;
    if (startDate) {
      start_date = String(startDate.getDate()) + '.' + String(startDate.getMonth() + 1) + '.' + String(startDate.getFullYear());
    } else {
      start_date = "";
    }
    if (endDate) {
      end_date = String(endDate.getDate()) + '.' + String(endDate.getMonth() + 1) + '.' + String(endDate.getFullYear());
    } else {
      end_date = "";
    }
    return api.get("/trials/"+trial_id+"/cumulative_report/?sdate=" + start_date + "&edate=" + end_date);
  }

}
export default new Data();
