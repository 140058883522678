import React, {useContext} from 'react';
import {StoreContext} from "../../utils/Store";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Data from '../../services/Data';

const PatientStatusSwitch = (props) => {

    const {setPatientStatus} = useContext(StoreContext);
    const patient = props.patient;
    const statusType = patient.status_type;
    const name = patient.status_text;

    const handleClick = (event) => {
        event.currentTarget.blur(); // Blur the button on click
        setPatientStatus(patient);
    };

    function getStatusClass() {
        if (statusType === -6) return "outline-first";
        if (statusType === -5) return "outline-danger";
        if (statusType === -4) return "outline-info";
        if (statusType === -3) return "outline-primary";
        if (statusType === -2) return "outline-danger";
        if (statusType === -1) return "outline-dark";
        if (statusType === 0) return "neutral-primary";
        if (statusType === 1) return "neutral-success";
        if (statusType === 2) return "neutral-danger";
        if (statusType === 3) return "neutral-dark";
        return "neutral-first"
    }

    return (
        <Button onClick={handleClick} color={getStatusClass()} className={"btn-sm"}>
            <FontAwesomeIcon icon={['fa', 'pen']} className="mr-2"/>
            {name}
        </Button>
    );

}

export default PatientStatusSwitch;
